
.pre-loader {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-self: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.sk-cube-grid {
    bottom: 0;
    height: 40px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
}

.sk-cube-grid {

  .sk-cube {
    width: 33%;
    height: 33%;
    background: #DB1B71;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }

  .sk-cube1 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
  }

  .sk-cube2 {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
  }

  .sk-cube3 {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
  }

  .sk-cube4 {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
  }

  .sk-cube5 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
  }

  .sk-cube6 {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
  }

  .sk-cube7 {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
  }

  .sk-cube8 {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
  }

  .sk-cube9 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
  }
}

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
    0%,
    70%,
    100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }
    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}



