@use '@angular/material' as mat;
@import 'variables.scss';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$doenkids-app-primary: mat.define-palette( $primaryTheme, 600 );
$doenkids-app-accent:  mat.define-palette( $secondaryTheme, A400, A200, A100 );

// The warn palette is optional (defaults to red).
$doenkids-app-warn:    mat.define-palette( $warnTheme );

// Create the theme object (a Sass map containing all of the palettes).
$doenkids-app-theme: mat.define-light-theme( $doenkids-app-primary, $doenkids-app-accent, $doenkids-app-warn );

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes( $doenkids-app-theme );

// Restyle material components
//
mat-toolbar.primary {
  background-color: mat.get-color-from-palette( $doenkids-app-primary );
}
