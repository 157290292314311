/* You can add global styles to this file, and also import other style files */
// @import '@fullcalendar/common/main.css';
// @import '@fullcalendar/daygrid/main.css';
@import 'variables.scss';

html,
body {
  height: 100%;
}
body, button {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

mark {
  background-color: $mainColor;
  color: $white;
}

a,
a:active,
a:link {
  color: $mainColor;

  --mat-tab-header-inactive-label-text-color: #{$mainColor};
}

.masonry-item {
  width: 200px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .masonry-item {
    width: 300px;
  }
}

.mat-badge .mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

.ql-divider {
  overflow: hidden;
}

button.doenkids-fab {
  position: fixed;
  bottom: 90px;
  right: 22px;
  z-index: 2000 !important;
}

.clickable {
  cursor: pointer;
}

.cell-wrapper {
  line-height: 38px;
}

.speeddial-container {
  position: fixed !important;
  bottom: 90px;
  right: 22px;
  z-index: 2000 !important;
}

// 1: concept
.publication-status-1 {
  transition: border 2s;
  border: 2px dashed $white-100 !important;
  border-radius: 10px !important;
  box-shadow: 0 0 0 4px $white, 2px 1px 6px 4px $transparent-500 !important;
  &:hover {
    border: 2px dashed #{$mainColor} !important;
  }
}

// 2: onder beoordeling
.publication-status-2 {
  transition: box-shadow 225ms;
  box-shadow: $publicationStatus2BoxShadow;
  &:hover {
    box-shadow: 0 0 0 3px $mainColor !important;
  }
}

// 4: excluded
.publication-status-4 {
  transition: filter 225ms;
  transition: opacity 225ms;
  filter: grayscale(1);
  opacity: 0.4;
  img {
    transition: filter 225ms;
    filter: blur(1px);
  }
  &:hover {
    filter: grayscale(0);
    img {
      filter: blur(0);
    }
  }
}

.pdf-container {
  padding: 1rem 1rem 1rem 0;
  height: calc(100% - 2rem);
  position: relative;
  ng2-pdfjs-viewer {
    height: 100%;
    width: 100%;
  }
}

quill-editor {
  z-index: 1000;
  border: 1px solid $white-100;
  display: block;
  margin-bottom: 22px;
  border-radius: 5px;
  width: 100%;

  .ql-container {
    z-index: 1001;

    .ql-editor {
      z-index: 1002;
      font-family: 'Roboto';
      font-size: 14px;

      p {
        padding: 0;
        padding-bottom: 14px;
      }

      &.ql-blank p {
        padding-bottom: 0;
      }

      &.ql-blank::before {
        content: none;
      }

      &.ql-blank::after {
        color: $transparent-600;
        content: attr(data-placeholder);
        display: block;
        font-style: italic;
        margin-top: -1.42em;
        min-height: 1em;
        pointer-events: none;
      }

      .ql-tooltip {
        z-index: 1003;
        width: auto !important;

        .ql-toolbar {
          z-index: 1004;

          .ql-formats {
            z-index: 1005;

            .ql-picker {
              z-index: 1006;

              .ql-picker-options {
                z-index: 1007;
              }
            }
          }
        }
      }
    }
  }
}

/* Localization via css because quill developers didn't think this through :) */
.ql-snow {
  .ql-tooltip::before {
    content: var(--i18n-quill-link);
    line-height: 26px;
    margin-right: 8px;
  }
  .ql-tooltip a.ql-action::after {
    content: var(--i18n-edit);
  }

  .ql-tooltip a.ql-remove::before {
    content: var(--i18n-remove);
  }

  .ql-tooltip[data-mode='link']::before {
    content: var(--i18n-quill-link-label);
  }
  .ql-tooltip.ql-editing a.ql-action::after {
    content: var(--i18n-save);
  }
}

hr {
  height: 1px;
  background-color: $messageParagraph;
  margin: unset;
  border: none;
  width: 100%;
}

.mdc-dialog__container {
  overflow-x: hidden;

  .mat-mdc-dialog-surface {
    overflow-x: hidden;
  }
}

mat-form-field:not([ng-reflect-appearance]) {
  .mat-mdc-text-field-wrapper {
    background-color: $transparent;
  }
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px !important;
}

@media only screen and (min-width: 1219px) {
  .ql-picker-options {
    transform: translateX(-50%);
  }
}

// overwrites the default styles of the pagination component
// for the whole project
.ngx-pagination {
  li.current {
    background-color: $mainColor;
    color: $white;
    border-radius: 4px;
    padding: 3px 8px;
  }

  .pagination-next > a,
  .pagination-previous > a {
    padding: 5px 10px;
    border-radius: 4px;
  }

  & li a {
    border-radius: 4px;
    padding: 3px 8px;
  }
}
